.content-with-sidebar {
  .content-title {
    font-size: rem-calc(22px);
    font-weight: 900;
    margin-bottom: 1rem;
  }

  aside {
    nav {
      ul {
        list-style: none;
        padding-left: 0;
        li {
          padding: .2rem 0;
          a {
            @extend .text-reset;
            text-decoration: none;
            &.active {
              font-weight: 700;
            }
          }
        }
      }
    }
    form {
      position: relative;
      align-items: center;
      padding: 1rem 0;
      > input.form-control {
        background: transparent;
        border: 1px solid $black;
        border-radius: 0;
        box-shadow: none;
        color: $black;
        line-height: 1.3;
        padding: .2rem .75rem .1rem;
        &::placeholder {
          font-weight: 300;
          font-size: $font-15;
          color: rgba(0, 0, 0, 0.7) !important;
        }
        &:focus {
          background: transparent;
          box-shadow: none;
        }
      }
      > button {
        background: transparent;
        border: none;
        position: absolute;
        top: 52%;
        transform: translateY(-50%);
        right: rem-calc(10px);
        height: .8rem;
        width: .8rem;
        background-image: $search-icon-black;
        background-repeat: no-repeat;
        background-size: .8rem;
      }
    }

    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-up(lg) {
      .primary-tagline {
        font-size: rem-calc(12px);
        margin-bottom: .2rem;
      }
    }
    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-down(lg) {
      margin-top: 0;
      .primary-tagline {
        padding-top: 1.4rem;
        margin-bottom: .4rem;
        border-top: primary-border(3px);
      }
    }
  }

  .accordion {
    .accordion-button {
      width: fit-content;
      padding: .8rem 0;
      // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
      @include media-breakpoint-down(sm) {
        padding: .4rem 0;
        display: block;
        max-width: 90%;
      }
      font-weight: 900;
      border-radius: 0;
      &::after {
        display: inline-block;
        margin-left: rem-calc(10px);
        height: calc(var(--bs-accordion-btn-icon-width) / 2);
        // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
        @include media-breakpoint-down(sm) {
          margin-left: rem-calc(6px);
          margin-bottom: rem-calc(2px);
        }
      }
      &:not(.collapsed) {
        color: $primary-green;
        background: inherit;
      }
      &:focus {
        box-shadow: none;
      }
      &.year-group {
        text-transform: uppercase;
        font-size: $font-15;
      }
      &.month-group, &.department-contacts {
        font-size: $font-18;
      }
    }
    .accordion-body {
      padding: 0;
      // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
      @include media-breakpoint-down(sm) {
        padding-bottom: 1rem;
      }
      .documents {
        div {
          display: flex;
          align-items: center;
          padding: .7rem 0;
          gap: 1rem;
          .pdf-doc {
            // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
            @include media-breakpoint-up(sm) {
              &::before {
                display: inline-block;
                width: rem-calc(26px);
                height: rem-calc(26px);
                content: "";
                background-image: $pdf-document-icon;
                background-repeat: no-repeat;
                background-size: rem-calc(26px);
              }
            }
            // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
          a {
            @extend .text-reset;
            text-decoration: none;
            font-size: $font-18;
            line-height: rem-calc(22px);
          }
        }
      }
      .contact-summary {
        margin-bottom: 1.5rem;
        p {
          font-size: $font-18;
          margin-bottom: 0
        }
      }
    }
  }

  .acts-archive-link {
    @extend .text-reset;
    text-decoration: none;
    font-size: $font-15;
    text-transform: uppercase;
    font-weight: 900;
    padding: .8rem 0;
    display: block;
    line-height: inherit;
  }

  .question-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 280px;
    padding-top: .5rem;
    input, textarea {
      width: 100%;
      border: 1px solid $black;
      padding: .2rem .5rem;
      &::placeholder {
        opacity: .6;
        font-weight: 300;
        font-size: $font-15;
      }
    }
    button {
      width: fit-content;
      font-weight: 700;
      font-size: $font-15;
      padding-top: .3rem;
      padding-bottom: .3rem;
      margin-top: .5rem;
    }
    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
