.contact-vcard {
    margin-top: 1rem;
    margin-bottom: 2rem;

    h4 {
        font-size: 1.125rem;
        font-weight: 400;
        margin-bottom: 0.1rem;
        line-height: 1.675rem;
    }

    h5 {
        font-size: 1.575rem;
        margin-bottom: 1.7rem;
    }
}

.contact-vcard__content {
    padding-top: 3.5rem;
}

.department-detail {
    h2 {
        margin-bottom: 1rem;
    }

    .department,
    .subdepartment,
    .subsubdepartment {
        margin-bottom: 3rem;
    }

    .department-contact {
        p {
            font-size: 1rem !important;
            padding-left: 0.5rem;
        }
    }
}

.by-department-cms-plugin {
    margin-bottom: 6rem;
}

.departments-list-cms-plugin {
    ul {
        list-style: none;
    }
}