footer {
  color: $white;
  font-weight: 300;
  font-size: rem-calc(14px);
  .container {
    justify-content: space-between;
    align-items: center;
    .social-links {
      display: flex;
      gap: .75rem;
      img {
        &.fb { height: rem-calc(20px) }
        &.tw { height: rem-calc(14px) }
        &.yt { height: rem-calc(13px) }
      }
    }
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-up(lg) {
    height: rem-calc(70px);
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    padding: 1.5rem 1rem;
    font-size: rem-calc(18px);
    .container {
      flex-direction: column;
      align-items: flex-start;
      .footer-text {
        order: 2;
        p {
          line-height: 1.3;
          > div { height: 1rem;}
        }
      }
      .social-links {
        padding-bottom: 1rem;
        justify-content: flex-start;
        order: 1;
        img {
          &.fb { height: rem-calc(22px) }
          &.tw { height: rem-calc(16px) }
          &.yt { height: rem-calc(14px) }
        }
      }
    }
  }
}
