$box-border-radius: 10px;

// CHARTS

$b: #5aa8e8;
$b-rgba: rgba(108,90,232,0.25);
$b-skills: #5E95E8;
$w: #fff;

// TRANSITIONS

$tr01: all .5s ease-in-out;
$bezier: cubic-bezier(.17,.67,0,1);

// SKILL BARS

$skills01: 90%;
$skills02: 90%;
$skills03: 80%;
$skills04: 70%;
$skills05: 75%;
$skills06: 60%;
$skills07: 80%;
$skills08: 75%;

$border-radius: 50px;

.bars {
    animation-name: skills;
}

#bars .bars p {
    font-weight: bold;
    padding-left: 20px;
}

// html {
//   font-size: 100%;
//   font-family: sans-serif;
// }

// body {
//   background: $b;
// }

// html, body, main {
//   padding: 0;
//   margin: 0;
//   box-sizing: border-box;
// }

// main {
// //   overflow: hidden;
// //   position: relative;
//   height: 100vh;
//   width: 100vw;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

section {
//   background: $b;

  article.bars {
    width: 100%;
    height: auto;
    max-width: none !important;
    border-top: 0px !important;

    p {
      z-index: 2;
      // color: $w;
      color: black;
      padding: 10px;
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
    }

    div {
      //

      span:nth-child(1) {
        z-index: -2;
        position: absolute;
        top: 0; bottom: 0; left: 0; right: 0;
        margin: auto;
        // background: $primary-yellow;
        box-shadow: inset 0 0 20px rgba(0,0,0,0.2);
        height: 100%;
        width: 100%;
        border-radius: $border-radius;
      }

      span:nth-child(2) {
        z-index: -1;
        position: absolute;
        top: 0; bottom: 0; left: 0; right: 0;
        background: $primary-green;
        height: 100%;
        border-radius: $border-radius;
      }
    }

    div:nth-child(1) {
      //

      span:nth-child(2) {
        width: $skills01;
        animation-delay: 0;
      }
    }

    div:nth-child(2) {
      //

      span:nth-child(2) {
        width: $skills02;
        animation-delay: 0.05s;
      }
    }

    div:nth-child(3) {
      //

      span:nth-child(2) {
        width: $skills03;
        animation-delay: 0.1s;
      }
    }

    div:nth-child(4) {
      //

      span:nth-child(2) {
        width: $skills04;
        animation-delay: 0.15s;
      }
    }

    div:nth-child(5) {
      //

      span:nth-child(2) {
        width: $skills05;
        animation-delay: 0.2s;
      }
    }

    div:nth-child(6) {
      //

      span:nth-child(2) {
        width: $skills06;
        animation-delay: 0.25s;
      }
    }

    div:nth-child(7) {
      //

      span:nth-child(2) {
        width: $skills07;
        animation-delay: 0.3s;
      }
    }

    div:nth-child(8) {
      //

      span:nth-child(2) {
        width: $skills08;
        animation-delay: 0.35s;
      }
    }
  }

//   &:before {
//     position: absolute;
//     top: -250px; left: -350px;
//     width: 500px;
//     height: 500px;
//     content: '';
//     // background: $b-rgba;
//     transform: rotate(30deg);
//   }

//   &:after {
//     position: absolute;
//     bottom: -250px; right: -350px;
//     width: 500px;
//     height: 500px;
//     content: '';
//     // background:$b-rgba;
//     transform: rotate(30deg);
//   }
}

.bars {
    // animation: skills 1.25s cubic-bezier(0.17, 0.67, 0, 1);
    animation: skills 1.25s $bezier;
    border-radius: $border-radius;
}

@keyframes skills {
    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}

// .bars {
//     animation: skills 1.25s $bezier;
// }

// @keyframes skills {
//     0% {
//         left: -500px;
//         opacity: 0;
//     }
//     100% {
//         left: 0;
//         opacity: 1;
//     }
// }



// DATA BOXES

.boxes-section {
  justify-content: center;
  --box-color: #bababa;

  .box-container {
      border: 2px solid var(--box-color);
      border-radius: $box-border-radius;
      background: linear-gradient(var(--box-color) 50%, transparent 50%);
      width: 212px;
      height: 100px;
      margin: 10px;
      display: flex;
      justify-content: center;

  }

  .box-header {
      // background-color: var(--box-color);
      // border-top-left-radius: $box-border-radius - 2px;
      // border-top-right-radius: $box-border-radius - 2px;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center !important;
      text-align: center !important;
      h3 {
          margin: 0px;
          color: white;
      }
  }

  .box-content {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
      p {
          margin: 0px;
          color: var(--box-color);
      }
  }
}

.legend {
    div {
        display: flex;
        color: white;
        font-weight: bold;
        padding: 8px;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
    }
    @media (min-width: 768px) {
        div:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        div:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    @media (max-width: 767px) {
        div:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        div:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    @media (max-width: 992px) {
      margin-top: 30px;
    }
}

    // colors
  .good {
    background-color: #73e7df;
  }
  .acceptable {
      background-color: #4fc5a5;
  }
  .moderate {
      background-color: #e7de00;
  }
  .bad {
      background-color: #f55050;
  }
  .very-bad {
      background-color: #99004c;
  }
  .extremely-bad {
      background-color: #7b227e;
  }
  .no-data {
      background-color: #969696;
  }


.legend-table {
  td, th {
    border: 1px solid #DDE0E1;
    // #f2f2f2
    padding: 8px;
  }
  .daker-row {
    background-color: #f2f2f2;
  }
  .contrast-text {
    color: white;
  }
}


.center-data {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
// CIRCULAR BARS

@property --p{
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.pie {
  --p:20;
  --b:32px;
  --c:darkred;
  --w:360px;

  width:var(--w);
  aspect-ratio:1;
  position:relative;
  display:inline-grid;
  margin:5px;
  place-content:center;
  font-size:36px;
  font-weight:bold;
  // font-family:sans-serif;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4), inset 0px 0px 56px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
.pie.table{
  // --p:100;
  // --c:#73e7df;
  color: var(--c);
  --w: 60px;
  --b: 5px;
  font-size: 32px;
  box-shadow: none;
}
.pie-text-table {
  display: block;
  font-weight: 700;
}
.pie:before,
.pie:after {
  content:"";
  position:absolute;
  border-radius:50%;

}
.pie:before {
  inset:0;
  background:
    radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
          mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
}
.pie:after {
  inset:calc(50% - var(--b)/2);
  background:var(--c);
  transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}
.animate {
  animation:p 1s .5s both;
}
.no-round:before {
  background-size:0 0,auto;
}
.no-round:after {
  content:none;
}
@keyframes p {
  from{--p:0}
}

// body {
//   background:#f2f2f2;
// }
