@use "sass:map";


@mixin search-form-wrapper-width {
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: rem-calc(0 20px);
  max-width: map.get($container-max-widths, "xxl");
  @include media-breakpoint-down(xxl) {
    max-width: map.get($container-max-widths, "xl")
  }
  @include media-breakpoint-down(xl) {
    max-width: map.get($container-max-widths, "lg")
  }
  @include media-breakpoint-down(lg) {
    max-width: map.get($container-max-widths, "md")
  }
  @include media-breakpoint-down(md) {
    max-width: map.get($container-max-widths, "sm")
  }
}

#carousel-featured .carousel-indicators {
  gap: rem-calc(10px);
  button {
    width: .6rem !important;
    height: .6rem !important;
    border-radius: 50% !important;
  }
}

#carousel {
  .search-form-wrapper {
    @include search-form-wrapper-width;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 1;
    top: rem-calc(35px);
    form {
      width: 320px;
      > input.form-control {
        background: transparent;
        border: white-border();
        border-radius: 0;
        box-shadow: none;
        height: rem-calc(34px);
        color: $white;
        &::placeholder {
          font-weight: 900;
          font-size: $font-15;
          color: rgba(255, 255, 255, 0.7) !important;
        }
        &:focus {
          background: transparent;
          border: white-border();
          box-shadow: none;
        }
      }
      > button {
        background: transparent;
        border: none;
        position: absolute;
        align-self: center;
        right: rem-calc(20px);
        > img { width: rem-calc(15px) }
      }
    }
  }
  .carousel-indicators {
    gap: rem-calc(10px);
    button {
      width: .6rem !important;
      height: .6rem !important;
      border-radius: 50% !important;
    }
  }
  .carousel-item {
    .image-container {
      position: relative;
      height: 700px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .carousel-content {
      width: 28rem;
      color: #fff;
      & > .date {
        font-weight: 900;
        font-style: normal;
        font-size: rem-calc(22px);
      }
      & .header {
        font-weight: 900;
        font-size: $font-60;
        margin: .75rem 0;
      }
      & > .content {
        font-size: rem-calc(18px);
        font-weight: 300;
        margin-bottom: 2rem;
      }
      & > .link {
        color: $white;
        text-decoration: none;
        border: white-border();
        font-weight: 500;
        font-size: rem-calc(15px);
        padding: 0.5rem .75rem;
        border: 3px solid #fff;
        width: fit-content;
      }
    }
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    .search-form-wrapper {
      justify-content: center;
      top: rem-calc(28px);
      form {
        width: 100%;
      }
    }
    .carousel-item {
      .image-container {
        // min-height: 520px;
        height: 520px;
      }
      .carousel-content {
        max-width: 85%;
        padding-top: rem-calc(35px);
        & > .date {
          font-weight: 600;
          font-size: rem-calc(18px);
        }
        & .header {
          font-weight: 700;
          font-size: rem-calc(50px);
          line-height: rem-calc(54px);
          margin: .75rem 0;
        }
        & > .content {
          margin-bottom: 1rem;
          font-size: rem-calc(16px);
        }
      }
    }
  }
}


section.quick-links {
  .cards {
    display: flex;
    justify-content: center;
    > a {
      flex-basis: calc(100% / 6);
      display: flex;
    }
    
    @include media-breakpoint-down(lg) {
      justify-content: flex-start;
      margin-left: 0rem;
      margin-right: 0rem;
      padding-top: rem_calc(10px);
      padding-bottom: rem_calc(10px);
      flex-wrap: nowrap;
      overflow-x: auto;
      gap: rem_calc(12px);
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      -ms-overflow-style: none;
      // scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }

      > a {
        flex: 0 0 auto;
        aspect-ratio: 1/1;
        width: 145px;
        height: 145px;
        min-width: 145px;
        padding: 0px;
        // scroll-snap-align: start;
      }
    }
  }
}

.quick-link-card {
  @extend .card;
  border: none;
  border-radius: 0;
  padding: 26px 12px 12px 12px;
  img { height: 2.6rem; }
  .card-body {
    font-weight: 300;
    line-height: 1.3;
    .card-title {
      font-size: rem-calc(15px);
    }
    .card-text {
      font-size: rem-calc(14px);
    }
  }
  transition: all .3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    width: rem-calc(145px);
    height: rem-calc(145px);
    img { height: rem-calc(50px); }
    .card-body {
        h5.card-title {
        font-size: rem-calc(12px);
      }
    }
  }
}


.vision-card {
  @extend .card;
  border-top: primary-border();
  border-left: primary-border();
  border-bottom: none;
  border-right: none;
  // margin: 0 115px;
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(xl) {
    margin: 0
  }
  
  .border-effect {
    border-bottom: primary-border();
    border-right: primary-border();
    width: 33%;
    height: 50%;
  }
  border-radius: 0;
  .row {
    > div:first-child {
      display: flex;
      align-items: center;
      
      #quote-carousel-wrap {
        display: flex;
        align-items: center;

        #carousel-quote {
          display: flex;
          align-items: center;
          width: 100%;
        }
      }
    }
    
    .card-body {
      padding: 75px 0px 20px 60px;
      
      @include media-breakpoint-down(xxl) {
        padding: 70px 0px 20px 70px;
      }
      
      @include media-breakpoint-down(lg) {
        margin-bottom: 3rem;
      }
      
      .card-title {
        font-size: rem-calc(29px);
        width: 80%;
        font-weight: 700;
        color: $primary-green;
        margin-bottom: 1.5rem;
        @include media-breakpoint-down(lg) {
          width: 100% !important;
          // text-align: center;
        }
      }

      .card-text {
        // font-size: $font-18;
        font-size: rem-calc(16px);
        margin-bottom: .5rem;
        // font-weight: 300;
        @include media-breakpoint-down(lg) {
          margin-bottom: 2rem !important;
        }
      }

      .signature {
        width: rem-calc(115px);
      }
    }
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-up(lg) {
    .row {
      --bs-gutter-x: 0rem;
      > div:first-child {
          padding-right: 0px;

          #quote-carousel-wrap {
            margin: 60px;
          }
      }
    }
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    border-top: primary-border(3px);
    border-left: primary-border(3px);
    margin: 0;
    padding: rem-calc(40px);
    .border-effect {
      border-right: 0;
      border-bottom: primary-border(3px);
      background: linear-gradient($primary-green 0 0) right 100% / 3px 70%; // right border;
      background-repeat: no-repeat;
      width: 100%
    }
    .row {
      > div:first-child {
        text-align: center;
        #quote-carousel-wrap {
          width: 100%;
          @include media-breakpoint-between(md, lg) {
            padding-top: 30px;
            padding-bottom: 30px;
          }
        }
      }
      .card-body {
        padding: 0 20%;
        .card-title {
          font-size: rem-calc(26px);
          width: 70%;
          margin-bottom: 1.5rem;;
        }
        .card-text {
          line-height: rem-calc(24px);
          margin-bottom: 1rem;
        }
        .signature {
          width: 84px;
          margin-bottom: 1.5rem
        }
      }
    }
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(md) {
    .row {
      > div:first-child {
        > img {
          width: 100%;
        }
      }
      .card-body {
        padding: 0;
      }
    }
  }
}


section.latest-news {
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    .news-card {
      &:nth-child(n+6) {
        display: none;
      }
    }
  }
}


section.featured {
  .featured-main {
    margin-bottom: rem-calc(50px);
    .featured-img {
      height: 520px;
      width: 100%;
      object-fit: cover;
    }
    .card {
      width: 75%;
      .card-body {
        .card-title {
          font-size: $font-45;
        }
      }
    }
  }
  .featured-links {
    > div {
      > a {
        height: 100%;
        background-color: $primary-green;
        font-size: $font-15;
        font-weight: 700;
        border-radius: 0;
        border: none;
        color: $white;
        min-height: rem-calc(50px);
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover { color: $white }
      }
    }
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    .featured-main {
      margin-bottom: rem-calc(30px);
      .featured-img {
        height: 420px;
      }
      .card {
        width: 88%;
        .card-body {
          .card-title {
            font-size: rem-calc(26px);
            line-height: rem-calc(30px);
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

section.events {
  .card {
    .card-img-overlay {
      span {
        span {
          font-size: 1rem;
        }
      }
      .card-title {
        font-size: $font-30;
      }
      &.big-text {
        .card-title {
          font-size: $font-45;
        }
      }
    }
    &.events-calendar {
      border: primary-border();
    }
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    .card {
      .card-img-overlay {
        span {
          span {
            font-size: 1rem;
          }
        }
        .card-title {
          font-weight: 900;
          line-height: rem-calc(34px);
        }
      }
      &.events-calendar {
        border: primary-border(3px);
      }
    }
  }
}


/////// Datepicker

.pika-single {
  border: none;
  .pika-lendar {
    float: none;
    width: inherit;
    margin: 0 .75rem .75rem .75rem;
  }
  .pika-title {
    display: flex;
    padding: .5rem 0 1rem;
    justify-content: space-between;
    .pika-label {
      @include tagline(14px);
      padding: 0;
      &:first-of-type {
        order: 2;
      }
      &:last-of-type {
        display: none;
      }
    }
    button {
      top: 10px;
      opacity: 1;
      background-image: none;
      &:first-of-type {
        @include caret-left($thick: 2px);
        order: 1;
                }
      &:last-of-type {
        @include caret-right($thick: 2px);
        order: 4;
      }
    }
  }
  .pika-table {
    border-collapse: inherit;
    border-spacing: 0 5px;
    thead {
      margin-bottom: .75rem;
      th {
        overflow: auto;
        abbr {
          font-weight: 900;
          font-size: .75rem;
          text-decoration: none;
          color: $black;
        }
      }
    }
    tbody {
      tr {
        td {
          overflow: auto;
          button {
            background: transparent;
            &:hover, &.is-selected {
              background: $primary-green;
              color: $white;
              border-radius: 3px;
              box-shadow: none;
            }
            font-weight: 400;
            font-size: .75rem;
            color: $black;
            text-align: center;
            @include media-breakpoint-only(lg) {
              line-height: 1;
            }
          }
          &.is-today {
            background: $primary-green;
            border-radius: 3px;
            button {
              color: $white;
            }
          }
        }
      }
    }
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(xxl) {
    .pika-table {
      border-spacing: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    height: calc(100vw - 20px);
    .pika-title {
      padding: 1rem 1rem .5rem;
      .pika-label {
        @include tagline(20px);
        &:first-of-type {
          order: 2;
        }
      }
      button {
        top: 10px;
        opacity: 1;
        background-image: none;
        &:first-of-type {
          @include caret-left($size: 8px);
          order: 1;
        }
        &:last-of-type {
          order: 4;
          @include caret-right($size: 8px);
        }
      }
    }
    .pika-table {
      border-spacing: 0 3.5vw;
      thead {
        th {
          abbr {
            font-size: rem-calc(16px);
          }
        }
      }
      tbody {
        tr {
          td {
            button {
              font-size: rem-calc(16px);
            }
          }
        }
      }
    }
  }
}

///////


section.for-citizens {
  .card {
    height: 100%;
    border: none;
    border-radius: 0;
    // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries
    container-type: inline-size;
    .card-body {
      padding: 20px 16px 0px 16px;
      .card-title {
        font-size: $font-15;
        border-top: 2px solid $white;
        border-left: 2px solid $white;
        background: linear-gradient(white 0 0) right 0 / 2px 65%; // right border;
        background-repeat: no-repeat;
        position: relative;
        &::after { // bottom border
          content: "";
          display: block;
          height: 2px;
          // 100% of container (.card) width - padding-left of the .card-body - left-border width
          width: calc(100cqw - 16px - 2px);
          background: #fff;
          bottom: 0;
          position: absolute;
          left: 0;
        }
        width: fit-content;
        padding: 6px 9px 6px 9px;
      }
      .list-group {
        font-size: $font-15;
        font-weight: 300;
        padding: 6px 0px 14px 12px;
        > a {
          border: none;
          border-radius: 0;
          padding: rem-calc(3px 0);
          display: flex;
          align-items: center;
          &::after {
            @include caret-right($size: 6px, $thick: 2px, $color: $white);
            content: '';
            margin-left: 3px;
          }
          &:last-of-type {
            font-weight: 500;
          }
          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    .card {
      .card-body {
        padding: 30px 22px 20px 22px;
        .card-title {
          font-size: $font-18;
          padding: 8px 12px 10px 12px;
          &::after { // bottom border
            // 100% of container (.card) width - padding-left of the .card-body - left-border width
            width: calc(100cqw - 22px - 2px);
          }
        }
        .list-group {
          a {
            font-size: $font-18;
          }
        }
      }
    }
  }
}

section.statictics {
  padding-top: 3.25rem;
  padding-bottom: 4.5rem;
  .statistics-row {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none; // IE and Edge
    &::-webkit-scrollbar { // Chrome, Safari
      display: none;
    }

    .card-wrapper {
      flex: 1 1 calc(100% / 6);
      max-width: 200px;
      // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries
      container-type: inline-size;
      .card {
        aspect-ratio: 1/1;
        width: calc(100cqw - 15cqw);
        min-height: 150px;
        height: 100%;
        border-left: primary-border();
        border-top: primary-border();
        border-right: none;
        border-bottom: none;
        border-radius: 0;
        background: linear-gradient($primary-green 0 0) right 0 / 2px 65%; // right border;
        background-repeat: no-repeat;
        &::after { // bottom border
          content: "";
          display: block;
          height: 2px;
          width: calc(100cqw - 2px); // minus card's left border width
          background: $primary-green;
          bottom: 0;
          position: absolute;
          left: 0;
        }
        .card-body {
          position: relative;
            .card-title {
              padding-top: 16%;
              font-size: $font-45;
              font-weight: 900;
              margin-bottom: 1.5rem;
            }
            .card-text {
              font-size: $font-15;
              font-weight: 900;
              text-align: center;
              line-height: 1;
            }
        }
      }
    }
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    padding-top: 2.5rem;
    padding-bottom: 3rem;
    .statistics-row {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-bottom: 1rem;
      gap: 1rem;
      .card-wrapper {
        flex: 1 0 calc(100% / 2.5);
        .card {
          min-height: 115px;
          min-width: 115px;
          border-left: primary-border(3px);
          border-top: primary-border(3px);
          background: linear-gradient($primary-green 0 0) right 0 / 3px 65%; // right border;
          background-repeat: no-repeat;
          &::after { // bottom border
            height: 3px;
            width: calc(100cqw - 3px); // minus card's left border width
          }
          .card-body {
            padding: 1rem;
            .card-title {
              @media (max-width: 500px) {
                padding-top: 10%;
              }
              @media (max-width: 450px) {
                padding-top: 0px;
              }
              font-size: rem-calc(36px);
            }
            .card-text {
              font-size: rem-cals(14px);
            }
          }
        }
      }
    }
  }
}
