@mixin list-item {
    display: flex;

    .department {
        .department-link {
            font-weight: 900;
        }
    }
}

.latest-official-forms {
    .item {
        @include list-item;
        align-items: baseline;
    }
}

.list-with-doc-icons {
    .item {
        @include list-item;
        align-items: flex-start;
    }
}