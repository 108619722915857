ul.pagination {
    .endless_separator {
        margin: 0 1rem;
    }

    .page-link {
        border-color: $primary-green;
        color: $primary-green;
    }

    li.page-item {
        &.active {
            span.page-link {
                background-color: $primary-green;
                color: white;
            }
        }
    }
}