@mixin fileicon-before {
  position: absolute;
  left: -34px;
//   top: 1px;
  display: block;
  width: 24px;
  height: 24px;
  content: "";
//   background: url("../img/fileicons/green/pdf.svg") no-repeat center center; // Default pdf icon for  files
  background: url("../img/fileicons/green/doc.svg") no-repeat center center; // Default icon for all files
  background-size: contain;
}

.file {
    margin-left: 2rem;
    line-height: 1.75rem;
}

// Old icons:

// .fileicon {
//   position: relative;
//   list-style-type: none;
// //   margin-bottom: 4px;
//   &.pdf {
//       &::before {
//          @include fileicon-before;
//       }
//   }
//   &.doc {
//       &::before {
//           @include fileicon-before;
//           background: url("../img/fileicons/doc.svg") no-repeat center center;
//           background-size: contain;
//       }
//   }
//   &.docx {
//       &::before {
//           @include fileicon-before;
//           background: url("../img/fileicons/docx.svg") no-repeat center center;
//           background-size: contain;
//       }
//   }
//   &.csv {
//       &::before {
//           @include fileicon-before;
//           background: url("../img/fileicons/csv.svg") no-repeat center center;
//           background-size: contain;
//       }
//   }
//   &.xls {
//       &::before {
//           @include fileicon-before;
//           background: url("../img/fileicons/xls.svg") no-repeat center center;
//           background-size: contain;
//       }
//   }
//   &.xlsx {
//       &::before {
//           @include fileicon-before;
//           background: url("../img/fileicons/xlsx.svg") no-repeat center center;
//           background-size: contain;
//       }
//   }

.fileicon {
    position: relative;
    list-style-type: none;
    &.pdf {
        &::before {
           @include fileicon-before;
        //    background: url("../img/fileicons/green/pdf.svg") no-repeat center center;
           background: url("../img/fileicons/green/doc.svg") no-repeat center center;
           background-size: contain;
        }
    }
    // For doc/docx/csv/xls/xlsx, the mixin already provides the green doc.svg
    &.doc, &.docx, &.csv, &.xls, &.xlsx {
        &::before {
            @include fileicon-before;
        }
    }

  &.yellow {
      &.pdf {
          &::before {
              position: absolute;
              left: 0;
              top: 16px;
              display: block;
              width: 24px;
              height: 24px;
              content: "";
              background: url("../img/fileicons/yellow/pdf.svg") no-repeat center center;
              background-size: contain;
          }
      }
      &.doc {
          &::before {
              background: url("../img/fileicons/doc.svg") no-repeat center center;
              background-size: contain;
          }
      }
      &.docx {
          &::before {
              background: url("../img/fileicons/yellow/docx.svg") no-repeat center center;
              background-size: contain;
              }
          }
      &.csv {
          &::before {
              background: url("../img/fileicons/yellow/csv.svg") no-repeat center center;
              background-size: contain;
          }
      }
      &.xls {
          &::before {
              background: url("../img/fileicons/yellow/xls.svg") no-repeat center center;
              background-size: contain;
          }
      }
      &.xlsx {
          &::before {
              background: url("../img/fileicons/yellow/xlsx.svg") no-repeat center center;
              background-size: contain;
          }
      }
  }
}

.survey-attachments-active,
.survey-attachments-ended {
    margin-bottom: 5rem;
}