#accordion-faq {
    .accordion-button {
        padding: 0;
        width: 100%;
        justify-content: space-between;
        font-size: 1rem;
    }
}

a.department-link {
    font-size: 1rem;
    text-transform: uppercase;
}