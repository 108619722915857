.city-administration-card {
  .card-title {
    font-size: rem-calc(22px);
    line-height: rem-calc(32px);
    font-weight: 900;
    padding: 1rem 0;
    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-down(md) {
      padding: .5rem 0 3vw;
    }
    @include media-breakpoint-down(sm) {
      font-size: $font-18;
    }
  }
}

section {
  .main-image img {
    @extend .img-fluid;
    margin-bottom: 1rem;
  }

  &.questions {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      > * {
        text-align: center;
      }
      h1 {
        font-size: $font-45;
        font-weight: 700;
      }
      p {
        font-size: $font-15;
        font-weight: 300;
        width: 35%;
      }
      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        width: 280px;
        padding-top: .5rem;
        input, textarea {
          width: 100%;
          border: 1px solid $black;
          padding: .2rem .5rem;
          &::placeholder {
            opacity: .6;
            font-weight: 300;
            font-size: $font-15;
          }
        }
        button {
          font-weight: 700;
          font-size: $font-15;
          padding-top: .3rem;
          padding-bottom: .3rem;
          margin-top: .5rem;
        }
      }
    }
    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-down(sm) {
      .container {
        p, form {
          width: 100%;
        }
      }
    }
  }
}
