.search-result-item .highlighted {
  // background: #ffea0f;
  background: #dce5a982;
  color: #000;
}

.frm-search {
  display: flex;
  margin-bottom: 2rem;

  .primary-btn-transparent {
    margin-left: 1rem;
  }
}

.search-result-item {
  margin-bottom: 3rem;

  .header {
    margin-bottom: 0.5rem;

    h4 {
      margin-bottom: 0px !important;
    }
  }
}