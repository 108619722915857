.landing-page-menu {
    ul.nav {
        list-style: none;
        padding-left: 0;

        li {
            padding: .2rem 0;

            a {
                @extend .text-reset;
                text-decoration: none;

                &.active {
                    font-weight: 700;
                }
            }
        }

        .nav-link-wrap {
            display: flex;
            align-items: center;
        }

        .nav-submenu {
            list-style: none;
        }
    }
}