.text--content {

    p,
    ul,
    ol,
    .noparagraf {
        font-size: 1.125rem;
        line-height: 1.75rem;
        margin-bottom: 2rem;
    }

    h1 {
        font-size: 1.7rem;
        line-height: 2.1rem;
        font-weight: 900;
        margin-bottom: 2rem;
    }

    h2 {
        font-size: 1.475rem;
        line-height: 1.875rem;
        font-weight: 900;
        margin-bottom: 2rem;
    }

    h3 {
        font-size: 1.225rem;
        line-height: 1.675rem;
        font-weight: 900;
        margin-bottom: 1.6rem;
    }

    h4 {
        font-size: 1.225rem;
        line-height: 1.675rem;
        font-weight: 500;
        margin-bottom: 1.1rem;
    }
}