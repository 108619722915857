.grid {
  .grid-sizer {
    width: 23%;
  }

  .gutter-sizer {
    width: 2%;
  }

  .grid-item {
    width: 23%;
    margin-bottom: 1.5rem;

    &.big {
      width: 48%;
    }
  }

  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    .grid-sizer {
      width: 48%;
    }

    .gutter-sizer {
      width: 4%;
    }

    .grid-item {
      width: 48%;

      &.big {
        width: 100%;
      }
    }
  }

  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(sm) {
    .grid-sizer {
      width: 100%;
    }

    .gutter-sizer {
      width: 0;
    }

    .grid-item {
      width: 100%;
    }
  }
}

.news-card {
  @mixin big-news-card {

    // @extend .news-card;
    .card-body {
      img {
        height: 315px;
      }

      .card-title {
        font-size: $font-34;
        line-height: rem-calc(38px);
      }
    }

    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-up(lg) {
      .card-body {
        .card-title {
          font-size: rem-calc(26px);
          line-height: rem-calc(30px);
        }
      }
    }
  }

  @extend .card;
  border-top: primary-border();
  border-bottom: none;
  border-right: none;
  border-left: none;
  border-radius: 0;

  .card-header {
    background: transparent;
    border: none;
    font-weight: 900;
    font-size: rem-calc(15px);
    padding: .4rem 0;
  }

  .card-body {
    padding: 0;

    img {
      margin-bottom: .75rem;
      object-fit: cover;
      object-position: center;
      height: 200px;
      width: 100%;
      object-fit: cover;
    }

    .card-title {
      margin-bottom: .75rem;
      font-weight: 900;
      font-size: rem-calc(22px);
      line-height: rem-calc(28px);
    }

    .card-text {
      font-weight: 400;
      font-size: rem-calc(18px);
      line-height: rem-calc(22px);
    }
  }

  &.big {

    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-up(sm) {
      @include big-news-card;
    }
  }

  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    border-top: primary-border(3px);

    .card-body {
      .card-text {
        line-height: rem-calc(24px);
      }
    }
  }

  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(sm) {
    .card-body {
      img {
        height: 200px;
      }
    }
  }
}

article {
  max-width: 800px;

  .date {
    font-size: rem-calc(14px);
    font-weight: 900;
    margin-bottom: .5rem;
  }

  .title {
    font-size: rem-calc(42px);
    font-weight: 900;
    line-height: rem-calc(46px);
    margin-bottom: 2.6rem;
  }

  .summary {
    font-size: rem-calc(18px);
    font-weight: 700;
    line-height: 1.3;
  }

  .featured-image {
    margin-bottom: 1rem;

    .image {
      position: relative;

      img {
        width: 100%;
        object-fit: cover;
        margin-bottom: .5rem;
      }

      button {
        position: absolute;
        right: rem-calc(25px);
        bottom: rem-calc(25px);
        background: transparent;
        width: rem-calc(38px);
        height: rem-calc(32px);
        background-size: 100% auto;
        background-repeat: no-repeat;
        border: none;
      }
    }

    .author {
      font-size: rem-calc(13px);

      >span {
        font-size: rem-calc(11px)
      }
    }
  }

  .text {
    font-size: rem-calc(18px);
    line-height: rem-calc(22.5px);
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .attachments {
    a {
      color: $primary-green;
      text-decoration: none;

    }
  }

  .share {
    display: flex;
    align-items: center;
    gap: 1rem;

    span {
      font-size: rem-calc(18px);
      font-weight: 700;
    }

    div {
      display: inline-flex;
      align-items: center;
      gap: .75rem;

      img {
        &.sh {
          height: rem-calc(20px)
        }

        &.fb {
          height: rem-calc(22px)
        }

        &.tw {
          height: rem-calc(16px)
        }

        &.yt {
          height: rem-calc(14px)
        }
      }
    }
  }

  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    width: 100%;
    border-top: primary-border(3px);

    .title {
      font-size: rem-calc(30px);
      line-height: rem-calc(34px);
    }

    .summary {
      line-height: rem-calc(24px);
    }

    .featured-image {
      .image {
        button {
          right: rem-calc(20px);
          bottom: rem-calc(25px);
          width: rem-calc(30px);
          height: rem-calc(26px);
        }
      }
    }

    .text {
      line-height: rem-calc(24px);
    }

    .share {
      gap: .75rem;

      span {
        display: inline-block;
        font-size: 1rem;
        line-height: rem-calc(24px);
      }

      div {
        padding-bottom: rem-calc(4px);
        gap: .5rem;

        img {
          &.sh {
            height: rem-calc(17px)
          }

          &.fb {
            height: rem-calc(17px)
          }

          &.tw {
            height: rem-calc(12px)
          }

          &.yt {
            height: rem-calc(11px)
          }
        }
      }
    }

    .to-top {
      a {
        font-size: 1rem;
        font-weight: 700;
        padding-right: 5px;

        i {
          margin-left: 5px;
          display: inline-block;
          @include caret($size: 10px, $thick: 2px, $rotate: 135deg, $translateY: -4px);
        }
      }
    }
  }

  @media (max-width: 340px) {
    .footer {
      .share span {
        font-size: .8rem
      }

      .to-top a {
        font-size: .8rem
      }
    }
  }
}

section.related {
  margin-bottom: 5rem;
}

#glightbox-slider {
  .gslide-description {
    background: transparent;

    .gdesc-inner {
      .gslide-desc {
        display: flex;
        justify-content: space-between;
        color: rgb(255, 255, 255, 0.7);
      }
    }
  }
}

.news-list__item {
  .news-list__item__content {
    h3 {
      font-size: 1.375em;
    }
  }
}

.news-list-text {
  .post {
    margin-top: rem-calc(12px);
    margin-bottom: rem-calc(12px);

    .meta {
      color: $primary-green;
      text-transform: uppercase;
      // font-family: Roboto;
      font-size: rem-calc(15px);
      font-weight: 500;
      line-height: rem-calc(18px);
      letter-spacing: 0px;
      text-align: left;
    }

    .title {
      // font-family: Roboto;
      font-size: rem-calc(17px);
      font-weight: 500;
      line-height: rem-calc(24px);
      letter-spacing: 0px;
      text-align: left;
    }
  }
}

.archive-filter {
  margin: 1rem auto 2rem;

  form {
    display: flex;
  }
}

// section.featured {
.featured-main {
  .card {

    @media (max-width: 425px) {
      min-height: 0;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      justify-items: normal;
      min-height: 326px;
    }
  }
}

// }