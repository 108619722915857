.filer_image_info {
    display: block;
    font-size: 1 rem;
    color: #999;
    font-style: italic;
}

.filer_image_wrap {

    &.right,
    &.left {
        width: 50%;
    }

    &.right {
        float: right;
        margin: 0 0 1.75rem 1.75rem;
    }

    &.left {
        float: left;
        margin: 0 1.75rem 1.75rem 0;
    }

    &.center {
        margin-bottom: 1.75rem;

        .filer_image {
            width: 100%;
        }
    }
}