nav.navbar {
  .navbar-brand {
    &:hover, &:focus {
      opacity: 1;
    }
    img {
      height: rem-calc(45px);
    }
  }
  .navbar-nav {
    .nav-link {
      font-weight: 900;
      font-size: rem-calc(12px);
      text-transform: uppercase;
      &:hover, &:focus {
        text-decoration: none;
      }
      &.with-border {
        margin: 0 0.75rem;
        padding: .25rem .75rem;
        border: white-border();
      }
      &.accessibility {
        padding-right: 0;
        img {
          height: 28px;
        }
      }
      &.active {
        color: $primary-yellow;
      }
    }
    .nav-item {
      #setLanguageForm {
        display: flex;
        align-items: center;
        .language-btn {
          display: inline;
          border: none;
          background: transparent;
          &.active {
            color: $primary-yellow;
          }
        }
      }
    }
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(lg) {
    .separator {
      margin-left: rem-calc(12px);
      margin-right: rem-calc(12px);
    }
    align-items: flex-start;
    .navbar-brand {
      img {
        height: rem-calc(40px);
      }
    }
    .mobile-nav-buttons {
      .accessibility {
        > img { height: rem-calc(30px) }
        &.show { display: none } // hide while menu is opened
      }
      .navbar-toggler {
        border: none;
        padding-right: 0;
        &:focus {
          box-shadow: none;
        }
        > .navbar-toggler-icon {
          display: inline-block;

        }
        &.collapsed > .navbar-toggler-icon {
          width: rem-calc(24px);
          background-image: $toggle-menu-icon-collapsed;
        }
        &:not(.collapsed) > .navbar-toggler-icon {
          width: rem-calc(16px);
          margin: rem-calc(0 4px);
          background-image: $toggle-menu-icon-expanded;
        }
      }
    }
    .navbar-collapse {
      > .navbar-nav {
        margin-top: 35%;
        height: 100vh;
        align-items: flex-end;

        .nav-item {
          .nav-link {
            font-size: 1rem;
            padding: rem-calc(10px 0);
            &.with-border {
              margin: 0;
              border: none;
            }
          }
          &.language-and-accessibility {
            > ul {
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              margin: rem-calc(0 4px);
              > .separator {
                padding: rem-calc(0 10px);
              }
            }
          }
        }
      }
    }
  }
}
